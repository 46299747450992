@charset 'UTF-8';

//Bootstrap
@import "../../../../node_modules/bootstrap/scss/mixins/banner";
@include bsBanner(Grid);

$include-column-box-sizing: true !default;


$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 3,
  8: $spacer * 3.5,
  9: $spacer * 4,
  10: $spacer * 4.5
);


@import "../../../../node_modules/bootstrap/scss/functions";
@import "../../../../node_modules/bootstrap/scss/variables";
@import "../../../../node_modules/bootstrap/scss/variables-dark";
@import "../../../../node_modules/bootstrap/scss/maps";

@import "../../../../node_modules/bootstrap/scss/mixins";


@import "../../../../node_modules/bootstrap/scss/vendor/rfs";

@import "../../../../node_modules/bootstrap/scss/root";
@import "../../../../node_modules/bootstrap/scss/helpers";

@import "../../../../node_modules/bootstrap/scss/containers";
@import "../../../../node_modules/bootstrap/scss/grid";

@import "../../../../node_modules/bootstrap/scss/utilities";
// Only use the utilities we need
// stylelint-disable-next-line scss/dollar-variable-default


$utilities: map-get-multiple(
  $utilities,
  (
    "display",
    "order",
    "flex",
    "flex-direction",
    "flex-grow",
    "flex-shrink",
    "flex-wrap",
    "justify-content",
    "align-items",
    "align-content",
    "align-self",
    "margin",
    "margin-x",
    "margin-y",
    "margin-top",
    "margin-end",
    "margin-bottom",
    "margin-start",
    "negative-margin",
    "negative-margin-x",
    "negative-margin-y",
    "negative-margin-top",
    "negative-margin-end",
    "negative-margin-bottom",
    "negative-margin-start",
    "padding",
    "padding-x",
    "padding-y",
    "padding-top",
    "padding-end",
    "padding-bottom",
    "padding-start",
    "visibility",
    "width",
    "max-width",
    "viewport-width",
    "min-viewport-width",
    "height",
    "max-height",
    "viewport-height",
    "min-viewport-height"



  )
);

@import "../../../../node_modules/bootstrap/scss/utilities/api";


// @import '../../../../node_modules/tailwindcss/dist/base';
// @import '../../../../node_modules/tailwindcss/dist/components';
// @import '../../../../node_modules/tailwindcss/dist/utilities';


//Ręcznie dodane
// @import 'bower_components/swiper/src/swiper.scss';
@import '../../../../node_modules/chocolat/src/css/chocolat.css';
@import '../../../../node_modules/swiper/swiper.css';


:root {
 --chocolat-icon-left: url(../../images/icon-left.svg);
 --chocolat-icon-right: url(../../images/icon-right.svg);
 --chocolat-icon-close: url(../../images/icon-close.svg);
 --chocolat-icon-loader: url(../../images/icon-loader.svg);
 --chocolat-icon-fullscreen: url(../../images/icon-fullscreen.svg);
}

